export const environment = {
  production: false,
  // AUTH_URL:"https://authentication.deloitte.cloneprod.sescdigital.org.br/",
  // URL_API: 'https://public.deloitte.cloneprod.sescdigital.org.br/public-area/',

  // AUTH_URL: "https://authentication.dev-v1.desenvolvimento.sescdigital.org.br/",
  // URL_API: "https://public.dev-v1.desenvolvimento.sescdigital.org.br/public-area/",
  // URL_SITE: 'http://localhost:4200/',
  // URL_MEDIA: 'http://www.sescsp.org.br',

  AUTH_URL: "",
  URL_API: "",
	URL_SITE: '',
  URL_MEDIA: '',

  // AUTH_URL: "https://authentication.dev-v1.desenvolvimento.sescdigital.org.br/",
  // URL_API: "https://public.dev-v1.desenvolvimento.sescdigital.org.br/public-area/",
	// URL_SITE: 'https://dev-v1-public.desenvolvimento.sescdigital.org.br/',
  // URL_MEDIA: 'http://www.sescsp.org.br/',


}