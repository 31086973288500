import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AboutComponent } from './views/about/about.component';
// import { ListComponent } from './views/list/list.component';
import { DetailsComponent } from './views/details/details.component';
// import { PublicacoesComponent } from './views/publicacoes/publicacoes.component';
// import { ExtrasComponent } from './views/extras/extras.component';

const routes: Routes = [  {
  path: "",
  redirectTo: '/home',
  pathMatch: 'full'
},{
  path: 'home',
  component: HomeComponent
},{
  path: 'sobre',
  component: AboutComponent
},{
  path: '**',
  redirectTo: '/home'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
