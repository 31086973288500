import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MockService } from './mock.service';
import { HelperService } from './helper.service';
// import {  } from  'ngx-ui-loader';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
	public useMock: boolean = true;
	public token: string;
	public language: string = '1';


	public onHold:boolean = false;
	public loading;
	public bearerToken;
	public fingerprint;
	public authenticated: boolean = false;
	public userData: any = {};
	public globalData: any = {};

	constructor(
		// private loader: NgxUiLoaderService,
		private http: HttpClient,
		private helper: HelperService,
		private mock: MockService,
	) {
		let LSData = ['language','bearerToken','fingerprint'];
		for(let i in LSData){
			if(localStorage[LSData[i]]) this[LSData[i]] = localStorage[LSData[i]];
		}
	}

	_apiCall(method, url, sendData: any, withLoading: boolean = false) {
		if(this.useMock === true){
			return new Promise<any>((resolve, reject) => {
				let content = this.mock.getMockData(url);
				if(content){
					setTimeout(()=>{
						resolve(content);
					},1000);
				}else{
					reject('not_found');
				}
			});

		}else{
			if(this.onHold){
				this.helper.sleep(2000);
			}
			return new Promise<any>((resolve, reject) => {
				if(withLoading) this.presentLoading();

				let opt:any = {};
				let fullUrl = '';
				let addTtoken = false;
				if((url.indexOf('://') > 0 || url.indexOf('../') >= 0)){
					fullUrl = url;
					if( url.indexOf('users/me') > 0 && url.indexOf(environment.AUTH_URL) >= 0){
						addTtoken = true;
					}
				}else{
					fullUrl = environment.URL_API + url;
					addTtoken = true;
				}

				if(addTtoken){
					opt.headers = new HttpHeaders().set('Authorization', this.getAuthorization()).set('Accept-Language', this.language);
				}
				opt.observe = 'response';


				let httpRequest;
				if(method == 'get' || method == 'delete'){
					httpRequest = this.http[method](fullUrl, opt);
				}else{
					httpRequest = this.http[method](fullUrl, sendData, opt);
				}

				httpRequest.subscribe((returnData: any) => {
					if(withLoading) this.dismissLoading();
					if(returnData.headers.get('Authorization') && method === 'post'){
						if(
							returnData.url.indexOf('accounts/signup') > 0 ||
							returnData.url.indexOf('login') > 0 ||
							returnData.url.indexOf('anonymous-user') > 0
						){
							this.bearerToken = returnData.headers.get('Authorization');
							localStorage.bearerToken = this.bearerToken;
						}
					}
					resolve(returnData.body);
				},(err) => {
					if(withLoading) this.dismissLoading();
					this.errorHandler(err);
					reject(err);
				});
			});
		}
	}

	_get(url:string, withLoading: boolean = false) {
		return this._apiCall('get', url, {}, withLoading);
	}

	_post(url, sendData, withLoading: boolean = true) {
		return this._apiCall('post', url, sendData, withLoading);
	}

	_put(url, sendData, withLoading: boolean = true) {
		return this._apiCall('put', url, sendData, withLoading);
	}

	_delete(url, withLoading: boolean = true) {
		return this._apiCall('delete', url, {}, withLoading);
	}


	getAuthorization() {
		let token = '';
		if(this.bearerToken) token += this.bearerToken;
		if(this.bearerToken && this.fingerprint) token += ',fp='+this.fingerprint;
		return token;
	}
	generateFingerPrint() {
		this.fingerprint = this.helper.salt(32);
		localStorage.fingerprint = this.fingerprint;
	}
	getGlobal() {
		return this.globalData;
	}

	retryApiCall() {
	}
	refreshToken() {
		localStorage.clear();
		this.generateFingerPrint();
		this.onHold = true;
		this._post(environment.AUTH_URL+'anonymous-user',{'fingerprint':this.fingerprint}).then((data)=>{
			this.onHold = false;
			// document.location.reload(true);
			// return true;
		});
	}
	presentLoading(msg: string = 'Por favor, aguarde...') {
		// this.loader.start();
		// this.loading = this.loadingCtrl.create({
		// 	content: msg,
		// 	dismissOnPageChange: false
		// });

		// this.loading.present();
	}

	dismissLoading(){
		// this.loader.stop();
		// return new Promise((resolve, reject) => {
		// 	this.loading.onDidDismiss(()=>{
		// 		resolve();
		// 	});

		// 	this.loading.dismiss();
		// });
	}

	errorHandler(error: any){
		if(error.error) error = error.error;
		if(error.status == '401'){
			if(this.onHold === false){
				this.refreshToken();
			}
		}else if(error.status == '403'){
			// trativa para requests sem permissão
		}else if(error.status == '400'){
			let showMsg = true;
			let msg = 'Oops';
			let title = '';
			// let jsonError = error.error;
			switch (error.error) {
				case "invalid_login":
					msg = 'Login e/ou Senha inválidos!';
					break;
				case "existing_user":
					msg = 'Usuário já existente';
					break;
				case "password_mismatch":
					msg = 'Senhas não conferem';
					break;
				case "mandatory_fields":
					msg = 'Campos obrigatórios';
					break;
				default:
					showMsg = false;
					break;
			}
			if(showMsg){
				// this.alertCtrl.create({
				// 	title: title,
				// 	subTitle: msg,
				// 	buttons: ['OK']
				// }).present();
			}
		}else{
			console.log(error);
		}
	}


}
