import { Component, OnInit } from '@angular/core';
// // import { BannerComponent } from '../banner/banner.component';
// // import { ImageComponent } from '../image/image.component';
import { ApiService } from '../../services/api.service';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public loaded: boolean = false;

    public banners = ['assets/images/banner-home.jpg'];
    public items: any = {
        first_row: [{
            'title_br': 'TÍTULO DE UMA',
            'author_name': 'Nome Sobrenome',
            'created': 'XX/XX/XXXX',
            'image': '../../../assets/images/image1.png',
            'short_content_br': `A Mauris in convallis sapien, ut
        hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
        eleifend sed erat non elementum.`
        },
        {
            'title_br': 'TÍTULO DE DUAS LINHAS MENOR',
            'author_name': 'Nome Sobrenome',
            'created': 'XX/XX/XXXX',
            'image': '../../../assets/images/image4.png',
            'short_content_br': `C Mauris in convallis sapien, ut
        hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
        eleifend sed erat non elementum.`
        },
        {
            'title_br': 'TÍTULO DE DUAS LINHAS DO ARTIGO',
            'author_name': 'Nome Sobrenome',
            'created': 'XX/XX/XXXX',
            'image': '../../../assets/images/image3.png',
            'short_content_br': `A Mauris in convallis sapien, ut
        hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
        eleifend sed erat non elementum.`
        }],
        last_row: [{
            'title_br': 'TÍTULO DE UMA',
            'author_name': 'Nome Sobrenome',
            'created': 'XX/XX/XXXX',
            'image': '../../../assets/images/image1.png',
            'short_content_br': `A Mauris in convallis sapien, ut
            hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
            eleifend sed erat non elementum.`
        },
        {
            'title_br': 'TÍTULO DE DUAS LINHAS MENOR',
            'author_name': 'Nome Sobrenome',
            'created': 'XX/XX/XXXX',
            'image': '../../../assets/images/image4.png',
            'short_content_br': `C Mauris in convallis sapien, ut
            hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
            eleifend sed erat non elementum.`
        },
        {
            'title_br': 'TÍTULO DE DUAS LINHAS DO ARTIGO',
            'author_name': 'Nome Sobrenome',
            'created': 'XX/XX/XXXX',
            'image': '../../../assets/images/image3.png',
            'short_content_br': `A Mauris in convallis sapien, ut
            hendrerit purus. Phasellus eleifend sed erat non elementum. Quisque nulla enim. Phasellus
            eleifend sed erat non elementum.`
        }]
    };


    constructor(
        public helper: HelperService,
        public api: ApiService
    ) { }

    ngOnInit() {
        this.loaded = true;
        this.helper.firstLoad = false;
        this.api._get('content-sources/16');




    }

}
