import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DomSanitizer } from "@angular/platform-browser";
// import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public firstLoad:boolean = true;
  public darkMode:boolean = false;
  public debouceController:any = {}

  constructor(private sanitizer: DomSanitizer) {}

  sleep(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
	};
  unbind(obj){
    return JSON.parse(JSON.stringify(obj));
  }
  debounce(fx,id,timer:number = 500){
    if(!id) return false;
    return new Promise((resolve,reject)=>{
      if(typeof(fx) == 'function'){
        if(this.debouceController[id]){
          clearInterval(this.debouceController[id]);
        }
        this.debouceController[id] = setTimeout(()=>{
          fx();
          delete this.debouceController[id];
          resolve();
        },timer);
      }
    });
  }
  slugify(str){
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++)
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

      return str;
  }
  toHHMMSS(secs) {
    let sec_num:any = parseInt(secs, 10); // don't forget the second param
    let hours:any   = Math.floor(sec_num / 3600);
    let minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds:any = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }

  salt(length:number = 15){
    let c = ''
    for(c = ''; c.length < length;) c += Math.random().toString(36).substr(2, 1);
    return c;
  }
  safeHTML(html){
      return this.sanitizer.bypassSecurityTrustHtml(html)
  }
  getImageUrl(url){
    if(!url) return '';
    if(url.indexOf('://')>0) return url;
    if(url.indexOf('/') === 0 ) url = url.substring(1);
    return environment.URL_MEDIA + url;
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}
