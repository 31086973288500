import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { MenuComponent } from './components/menu/menu.component';
// import { FooterComponent } from './components/footer/footer.component';
// import { ListComponent } from './views/list/list.component';
// import { ContentComponent } from './components/content/content.component';
// import { BannerComponent } from './components/banner/banner.component';
// import { PublicacoesComponent } from './views/publicacoes/publicacoes.component';
// import { ExtrasComponent } from './views/extras/extras.component';
// import { BoxComponent } from './components/box/box.component';
// import { ImageComponent } from './components/image/image.component';
// import { ToolbarComponent } from './components/toolbar/toolbar.component';
// import { LoaderComponent } from './components/loader/loader.component';
// import { PlayerComponent } from './components/player/player.component';
import { HomeComponent } from './views/home/home.component';
import { DetailsComponent } from './views/details/details.component';
import { AboutComponent } from './views/about/about.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    // MenuComponent,
    // FooterComponent,
    // ListComponent,
    // ContentComponent,
    // BannerComponent,
    HomeComponent,
    DetailsComponent,
    // PublicacoesComponent,
    // ExtrasComponent,
    // BoxComponent,
    // PlayerComponent,
    // LoaderComponent,
    AboutComponent,
    // ImageComponent,
    // ToolbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
