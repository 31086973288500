import { Component, OnInit, HostListener } from '@angular/core';
// import { ContentComponent } from '../../components/content/content.component';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit {

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch(event.keyCode){
      case 27:
        this.closeModal();
        break;
      case 39:
        if(this.modalContent !== false) this.navModal(1);
        break;
      case 37:
        if(this.modalContent !== false) this.navModal(-1);
        break;
    }
  }

  public modalContent:any = false;
  public videos = [];
  public images = [];
  public mockVideos = [{
    id: '1',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 1',
    description: 'Primeiro encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela -1.jpg'
  },
  {
    id: '2',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 2',
    description: 'Segundo encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 13.55.51.jpg'
  },
  {
    id: '3',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 3',
    description: 'Terceiro encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 13.58.18.jpg'
  },
  {
    id: '4',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 4',
    description: 'Quarto encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 14.01.40.jpg'
  },
  {
    id: '5',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 5',
    description: 'Quinto encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 14.05.49.jpg'
  },
  {
    id: '6',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 6',
    description: 'Sexto encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 14.09.54.jpg'
  },
  {
    id: '7',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 7',
    description: 'Sétimo encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 14.16.11.jpg'
  },
  {
    id: '8',
    title: 'TERRA COMUNAL - ENCONTROS COM ABRAMOVIC - 8',
    description: 'Oitavo encontro sobre arte conceitual e performances com a artista Marina Abramovic',
    thumb: 'assets/images/static/Captura de Tela 2019-03-13 às 14.20.28.jpg'
  }];

  public mockImages = [
    {
      id: '1',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group -1.jpg',
      fileId: 'assets/images/static/a/Mask Group -1.jpg'
    },{
      id: '2',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group -2.jpg',
      fileId: 'assets/images/static/a/Mask Group -2.jpg'
    },{
      id: '3',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group -3.jpg',
      fileId: 'assets/images/static/a/Mask Group -3.jpg'
    },{
      id: '4',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group -4.jpg',
      fileId: 'assets/images/static/a/Mask Group -4.jpg'
    },{
      id: '5',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group -5.jpg',
      fileId: 'assets/images/static/a/Mask Group -5.jpg'
    },{
      id: '6',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group -6.jpg',
      fileId: 'assets/images/static/a/Mask Group -6.jpg'
    },{
      id: '7',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 93.jpg',
      fileId: 'assets/images/static/a/Mask Group 93.jpg'
    },{
      id: '8',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 95.jpg',
      fileId: 'assets/images/static/a/Mask Group 95.jpg'
    },{
      id: '9',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 96.jpg',
      fileId: 'assets/images/static/a/Mask Group 96.jpg'
    },{
      id: '10',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 97.jpg',
      fileId: 'assets/images/static/a/Mask Group 97.jpg'
    },{
      id: '11',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 98.jpg',
      fileId: 'assets/images/static/a/Mask Group 98.jpg'
    },{
      id: '12',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 99.jpg',
      fileId: 'assets/images/static/a/Mask Group 99.jpg'
    },{
      id: '13',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 100.jpg',
      fileId: 'assets/images/static/a/Mask Group 100.jpg'
    },{
      id: '14',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 101.jpg',
      fileId: 'assets/images/static/a/Mask Group 101.jpg'
    },{
      id: '15',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 102.jpg',
      fileId: 'assets/images/static/a/Mask Group 102.jpg'
    },{
      id: '16',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 103.jpg',
      fileId: 'assets/images/static/a/Mask Group 103.jpg'
    },{
      id: '17',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 104.jpg',
      fileId: 'assets/images/static/a/Mask Group 104.jpg'
    },{
      id: '18',
      title: 'O JARDIM',
      date: '01/01/2020',
      description: 'Quisque nulla enim, auctor quis bibendum ac, rhoncus at ex. Pellentesque ligula ligula, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vulputate tellus sed nulla facilisis, eget tristique massa blandit. ',
      thumb: 'assets/images/static/a/Mask Group 105.jpg}',
      fileId: 'assets/images/static/a/Mask Group 105.jpg'
    }
  ];

  public loaded:boolean = false;
  public content:any = {
    title:'ENCONTROS COM ABRAMOVIC',
    description: '<p>Nesta série de oito encontros a artista Marina Abramovic aborda temas variados como arte, performance e outros campos relacionados. Durante alguns encontros, Abramovic potencializa a energia criada entre ela e o público no espaço trazendo performances executadas por Lynsey Peisinger, artista, coreógrafa e colaboradora do MAI.</p>'
  };
  // public content = [{
  //   title_br: "Estações do Corpo e do Espírito",
  //   featured_image: "assets/images/details1.png",
  //   content_br: "<p><b><i>Teste de Legenda</i></b></p><p><b><i>Outro Teste de Legenda</i></b></p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p>"
  // },{
  //   title_br: "A linguagem expressiva dos corpos",
  //   featured_image: "assets/images/details2.png",
  //   featured_video: "5657c9a1dd1596e8a769981e75f01814",
  //   content_br: "<p><b><i>Teste de Legenda</i></b></p><p><b><i>Outro Teste de Legenda</i></b></p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p>"
  // },{
  //   title_br: "Corpo Vivo",
  //   featured_image: "assets/images/details3.png",
  //   content_br: "<p><b><i>Teste de Legenda</i></b></p><p><b><i>Outro Teste de Legenda</i></b></p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ipsam voluptates, sed quis nisi aspernatur alias facere quaerat odio inventore excepturi nostrum dolores corporis maxime suscipit, fuga ex aliquam totam.</p>"
  // }]
  constructor(
    public api: ApiService,
    public helper: HelperService,
    public route: ActivatedRoute
  ) {
    this.loaded = true;
    this.helper.firstLoad = false;
    let currentPath = this.route.url['_value'][0].path;
    let hasPlaylist = ['encontros-com-abramovic','oito-performances'];
    let hasMosaic = ['galeria-de-fotos'];

    if(this.api.useMock){
      if(hasMosaic.indexOf(currentPath)>=0){
        this.images = this.mockImages;
      }
      if(hasPlaylist.indexOf(currentPath)>=0){
        this.videos = this.mockVideos;
      }
    }

    this.api._get('contents/'+this.route.params['_value'].id).then((data)=>{
      this.content = data;
      if(hasMosaic.indexOf(currentPath)>=0){
        // ?? Verificar como deve carregar a galeria de imagens
      }
      if(hasPlaylist.indexOf(currentPath)>=0){
        this.api._get('collections/'+this.route.params['_value'].id+'/playlist/').then((playlistData)=>{
          this.content.related = [];
          let loadedCount = 0;
          for(var i in playlistData.items){
            this.api._get('collections/'+this.route.params['_value'].id+'/playlist/'+playlistData.items[i].id).then((playlistDataDetails)=>{
              playlistDataDetails.title = playlistDataDetails.translatedContent.title;
              playlistDataDetails.content = playlistDataDetails.translatedContent.shortDescription;
              playlistDataDetails.featured_image = playlistDataDetails.featured ? playlistDataDetails.featured : playlistDataDetails.thumbnail;
              this.content.related.push(playlistDataDetails);
              loadedCount++;
              if(loadedCount == playlistData.items.length){
                this.loaded = true;
                this.helper.firstLoad = false;
              }
            })
          }
        })
      }else{
        this.loaded = true;
        this.helper.firstLoad = false;
      }
    })
  }
  getMosaicBackground(image){
    return 'url("' + image + '")';
  }
  mosaicClick(image){
    this.modalContent = image;
    this.modalContent.template = 'image';
    this.helper.darkMode = true;
  }
  videoClick(video){
    this.modalContent = video;
    this.modalContent.template = 'video';
    this.helper.darkMode = true;
  }
  closeModal(){
    this.modalContent = false;
    this.helper.darkMode = false;
  }
  navModal(direction){
    let template = this.modalContent.template;
    let collection = this[template + 's'];
    let currentId = this.modalContent.id;
    for(let i in collection){
      let item = collection[i];
      if(item.id === currentId){
        if(parseInt(i) === 0 && direction < 0){
          this.modalContent = collection[collection.length - 1];
        }else if( (parseInt(i) + 1) >= collection.length && direction > 0){
          this.modalContent = collection[0];
        }else{
          this.modalContent = collection[parseInt(i) + direction];
        }
        this.modalContent.template = template;
      }
    }
  }
  nav(item){
    // this.router.navigateByUrl(this.router.url + '/' + item.id);
    this.content.featured_video = item.fileId;
    // this.content.
    delete this.content.related;

  }

  ngOnInit() {
  }

}
